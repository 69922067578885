import React from 'react';
import styled from '@emotion/styled';

import Layout from '~/components/layout';
import SEO from '~/components/seo';

import useListData from '~/hooks/useListData';
import Section from '~/components/section';

const SectionGrid = styled.div`
  display: grid;
  gap: 3rem 1rem;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
`;

const IndexPage = () => {
  const { sections } = useListData();
  return (
    <Layout>
      <SEO title="Coronavirus Lists" />

      <SectionGrid className="mt-4">
        {sections.map((section, index) => (
          <Section
            key={index}
            background={section.background}
            title={section.title}
            links={section.links}
          />
        ))}
      </SectionGrid>
    </Layout>
  );
};

export default IndexPage;
