import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import tw from 'twin.macro';

import Img from 'gatsby-image';

import List from '~/components/list';
import useHover from '~/hooks/useHover.ts';

const SectionWrapper = styled.div`
  position: relative;
`;

const CardWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const SectionHeader = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  ${tw`bg-x-slateblue`}
  ${tw`text-x-lightgray font-thin text-xl`}
  ${tw`text-center`}
  ${tw`px-8 py-4`}

  font-family: "Poppins", sans-serif;
`;

const Section = ({ title, background, links }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isHovered, hoverRef] = useHover();
  useEffect(() => {
    setIsExpanded(isHovered);
  }, [isHovered]);
  const toggleExpanded = () => setIsExpanded(!isExpanded);

  return (
    <SectionWrapper ref={hoverRef} onClick={toggleExpanded}>
      <CardWrapper>
        <SectionHeader>{title}</SectionHeader>
        <Img
          sizes={{ ...background.childImageSharp.fluid, aspectRatio: 5 / 3 }}
        />
      </CardWrapper>

      <List
        isExpanded={isExpanded}
        links={links}
        toggleExpanded={toggleExpanded}
      />
    </SectionWrapper>
  );
};

Section.propTypes = {
  title: PropTypes.string,
  background: PropTypes.object,
  links: PropTypes.array,
};

export default Section;
