import { useStaticQuery, graphql } from 'gatsby';

const useListData = () => {
  const { dataYaml: data } = useStaticQuery(
    graphql`
      query ListData {
        dataYaml {
          sections {
            title
            background {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            links {
              name
              url
            }
          }
        }
      }
    `
  );
  return data;
};

export default useListData;
