import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import tw from 'twin.macro';
import { FiPlus, FiMinus } from 'react-icons/fi';

import useDimensions from '~/hooks/useDimensions.tsx';

const ListWrapper = styled.div`
  ${tw`bg-x-lightgray`}

  position: absolute;
  top: 100%;

  width: 100%;
  height: auto;

  z-index: 0;
  transition: z-index 0.2s steps(1, end);

  &.expanded {
    z-index: 1;
    transition: z-index 0.2s steps(1, start);
  }
`;

const ListInner = styled.div`
  overflow: hidden;

  transition: height 0.2s ease-in-out;
  height: ${(props) => (props.isExpanded ? `${props.height}px` : 0)};
  /* height: auto; */

  animation: show 2s linear;
`;

const ListElement = styled.ul`
  ${tw`border-x-brown border-l-2 border-r-2 border-b-2`}
  ${tw`px-4 py-3`}
`;

const IconWrapper = styled.div`
  position: absolute;
  ${tw`bg-x-brown w-8 h-8`}
  ${tw`border-2 border-x-lightgray`}

  /* Position icon in the center */
  ${tw`left-0 right-0 mx-auto`}
  margin-top: -1rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const List = ({ isExpanded, links, toggleExpanded }) => {
  const [dimensionRef, dimensions] = useDimensions();
  return (
    <ListWrapper className={isExpanded ? 'expanded' : ''}>
      <ListInner isExpanded={isExpanded} height={dimensions.height}>
        <ListElement ref={dimensionRef}>
          {links.map((link, index) => (
            <a
              key={index}
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              className="block py-3"
            >
              <div className="hover:underline">{link.name}</div>
            </a>
          ))}
        </ListElement>
      </ListInner>
      <IconWrapper>
        <>
          {isExpanded ? (
            <FiMinus
              className="text-x-lightgray"
              size="1.5rem"
              strokeWidth="3px"
              onClick={toggleExpanded}
            />
          ) : (
            <FiPlus
              className="text-x-lightgray"
              size="1.5rem"
              strokeWidth="3px"
              onClick={toggleExpanded}
            />
          )}
        </>
      </IconWrapper>
    </ListWrapper>
  );
};

List.propTypes = {
  isExpanded: PropTypes.bool,
  links: PropTypes.array,
  toggleExpanded: PropTypes.func,
};

export default List;
